var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "content" } }, [
    _vm._m(0),
    _c("div", { staticClass: "bg-white py-4 px-3 px-md-4 comparison-info" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "product-retailers" },
        _vm._l(_vm.products, function(product, index) {
          return _c("div", { key: product.id, staticClass: "mb-5" }, [
            _c("div", { staticClass: "product-wrapper" }, [
              _c("div", { staticClass: "product border-bottom mb-4 pb-4" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-sm-auto mb-4 mb-sm-0" }, [
                    _c(
                      "div",
                      { staticClass: "slider product-slider mb-0 shadow-none" },
                      [
                        _c("figure", [
                          _c("img", {
                            staticStyle: { width: "auto", height: "auto" },
                            attrs: {
                              src:
                                "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                product.featured_image +
                                ".jpg",
                              alt: product.code
                            }
                          })
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col-sm" }, [
                    _c("div", { staticClass: "details" }, [
                      _c("h5", { staticClass: "text-primary" }, [
                        _vm._v(
                          " " +
                            _vm._s(product.brand.name) +
                            " " +
                            _vm._s(product.series) +
                            " " +
                            _vm._s(product.code) +
                            " " +
                            _vm._s(product.name) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "meta mb-0" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://media3.bosch-home.com/Documents/specsheet/en-GB/" +
                                product.code +
                                ".pdf"
                            }
                          },
                          [_vm._v("Please click here for full specification")]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "row retailer-wrapper" },
              _vm._l(_vm.retailers[index], function(retailer) {
                return _c(
                  "div",
                  { key: retailer.RetailerId, staticClass: "col-sm-6 mb-4" },
                  [
                    _c("div", { staticClass: "retailer row" }, [
                      _c("div", { staticClass: "col-auto" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "image mb-0 p-2 h-100 bg-transparent border rounded-0"
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: retailer.RetailerLogoUrl,
                                alt: retailer.FriendlyName
                              }
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "details" }, [
                          _c("span", { staticClass: "price" }, [
                            _vm._v(
                              _vm._s(retailer.RetailerCurrencySymbol) +
                                _vm._s(retailer.Price)
                            )
                          ]),
                          retailer.Availability == "InStock"
                            ? _c("div", { staticClass: "in-stock" }, [
                                _c("i", { staticClass: "fa fa-check" }),
                                _vm._v(" In Stock ")
                              ])
                            : _c("div", { staticClass: "in-stock not" }, [
                                _c("i", { staticClass: "fa fa-times" }),
                                _vm._v(" Out of Stock ")
                              ]),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-secondary-dark theme-bg",
                              attrs: {
                                href: retailer.ClickThruUrl,
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Retailer")]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        }),
        0
      ),
      _vm._m(2),
      _c(
        "div",
        {
          staticClass: "comparison-wrapper table-responsive mb-5",
          class: [_vm.products.length > 0 ? "" : "overflow-hidden"]
        },
        [
          _vm.products.length > 0
            ? _c("table", { staticClass: "table1" }, [
                _c(
                  "tbody",
                  [
                    _c("tr", [
                      _c("td", [
                        _c("header", [
                          _c("table", { staticClass: "inner-table" }, [
                            _c("tbody", [
                              _c(
                                "tr",
                                [
                                  _c("th", [
                                    _c("p", [
                                      _vm._v(" Here are the "),
                                      _c("a", { attrs: { href: "#" } }, [
                                        _vm._v(
                                          _vm._s(_vm.products[0].category.name)
                                        )
                                      ]),
                                      _vm._v(" you’ve chosen to compare ")
                                    ]),
                                    _c("p", { staticClass: "show" }, [
                                      _vm._v("Show Differences")
                                    ]),
                                    _vm._m(3)
                                  ]),
                                  _vm._l(_vm.products, function(product) {
                                    return _c("td", { key: product.id }, [
                                      _c("div", { staticClass: "product" }, [
                                        _c("div", { staticClass: "image" }, [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                                product.featured_image +
                                                ".jpg",
                                              alt: product.name
                                            }
                                          })
                                        ]),
                                        _c("div", { staticClass: "details" }, [
                                          _c("h4", [
                                            _vm._v(
                                              " " +
                                                _vm._s(product.brand.name) +
                                                " " +
                                                _vm._s(product.series) +
                                                " " +
                                                _vm._s(product.name) +
                                                " "
                                            )
                                          ]),
                                          _c(
                                            "p",
                                            { staticClass: "mb-2 theme-text" },
                                            [_vm._v(_vm._s(product.code))]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "mb-0 d-block theme-text",
                                              attrs: {
                                                href:
                                                  "https://media3.bosch-home.com/Documents/specsheet/en-GB/" +
                                                  product.code +
                                                  ".pdf"
                                              }
                                            },
                                            [_vm._v("Full spec")]
                                          )
                                        ])
                                      ])
                                    ])
                                  })
                                ],
                                2
                              )
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _vm._l(_vm.sections, function(section) {
                      return [
                        section.attributes.length > 0
                          ? [
                              _c("tr", { key: section.code }, [
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "accordion-head",
                                      attrs: {
                                        "data-toggle": "collapse",
                                        "data-target":
                                          "#section-" + section.code,
                                        "aria-expanded": "true",
                                        "aria-controls":
                                          "section-" + section.code
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(section.name) + " ")]
                                  )
                                ])
                              ]),
                              _c("tr", { key: section.code }, [
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "collapse show table-wrapper",
                                      attrs: { id: "section-" + section.code }
                                    },
                                    [
                                      _c(
                                        "table",
                                        { staticClass: "inner-table" },
                                        [
                                          _c(
                                            "tbody",
                                            _vm._l(section.attributes, function(
                                              attr
                                            ) {
                                              return _c(
                                                "tr",
                                                {
                                                  key: attr.attribute.api_name
                                                },
                                                [
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        attr.attribute.name
                                                      )
                                                    )
                                                  ]),
                                                  _vm._l(_vm.products, function(
                                                    product
                                                  ) {
                                                    return _c(
                                                      "td",
                                                      { key: product.id },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getProductAttributeValue(
                                                                product,
                                                                attr.attribute
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ])
            : _c("table", { staticClass: "table1" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("header", [
                        _c("table", { staticClass: "inner-table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", { staticClass: "inner" }, [
                                _vm._m(4),
                                _c(
                                  "p",
                                  [
                                    _c("router-link", { attrs: { to: "/" } }, [
                                      _vm._v(" Start Searching"),
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "4.421",
                                            height: "6.948",
                                            viewBox: "0 0 4.421 6.948"
                                          }
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                id: "small-down",
                                                transform:
                                                  "translate(0 6.948) rotate(-90)"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  id: "Path_26",
                                                  "data-name": "Path 26",
                                                  d:
                                                    "M6.074,9.021,2.6,5.51l.9-.91L6.074,7.2,8.647,4.6l.9.91Z",
                                                  transform:
                                                    "translate(-2.6 -4.6)",
                                                  fill: "#095691"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
        ]
      ),
      _vm._m(5),
      _c("div", { staticClass: "mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "box1 bg-primary-light text-white text-center rounded-lg"
          },
          [
            _c("h5", { staticClass: "font-weight-bold mb-3" }, [
              _vm._v(
                "Was your appliance deemed unrepairable from one of our engineer visits?"
              )
            ]),
            _c("h5", { staticClass: "font-weight-bold mb-3" }, [
              _vm._v("Receive a £50 Refund ")
            ]),
            _c("p", [
              _vm._v(
                "Don’t forget, as a gesture of goodwill, if you choose to replace your appliance with one of our products within four weeks of the date of your engineer visit, we will refund £50 from the cost of the engineer or our service partner’s callout charge. "
              )
            ]),
            _c(
              "p",
              [
                _vm._v("To claim your cashback, please click "),
                _c(
                  "router-link",
                  {
                    staticClass: "text-primary text-underline",
                    attrs: { to: "/redeem" }
                  },
                  [_vm._v("HERE")]
                ),
                _vm._v(" T's & C's apply")
              ],
              1
            )
          ]
        )
      ]),
      _vm._m(6)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "info-block redeem-cashback" }, [
        _c("header", [
          _c("div", { staticClass: "logo" }, [
            _c("img", {
              attrs: {
                src: require("./../../../assets/images/logo.png"),
                alt: "BSH"
              }
            })
          ]),
          _c("div", { staticClass: "brand-logos d-flex" }, [
            _c("div", { staticClass: "logo" }, [
              _c("img", {
                attrs: {
                  src: require("./../../../assets/images/bosch-logo.png"),
                  alt: "Bosch"
                }
              })
            ]),
            _c("div", { staticClass: "logo" }, [
              _c("img", {
                attrs: {
                  src: require("./../../../assets/images/siemens-logo.png"),
                  alt: "siemens"
                }
              })
            ]),
            _c("div", { staticClass: "logo" }, [
              _c("img", {
                attrs: {
                  src: require("./../../../assets/images/neff.png"),
                  alt: "neff"
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md" }, [
            _c("h1", { staticClass: "theme-text text-center" }, [
              _vm._v("Your Product Comparison")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "greeting" }, [
      _c("p", { staticClass: "text-primary-light mb-1" }, [
        _vm._v("Dear Customer")
      ]),
      _c("p", [
        _vm._v(
          "It was great to meet you today! Please see below the products we discussed below:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-md-5 mb-4" }, [
      _c("div", { staticClass: "col-md" }, [
        _c("h2", { staticClass: "theme-text text-center" }, [
          _vm._v("Comparison Table")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "toggle-switch", attrs: { for: "switch1" } },
      [
        _c("input", {
          staticClass: "show-difference",
          attrs: { type: "checkbox", id: "switch1" }
        }),
        _c("span", { staticClass: "slider" }, [
          _c("span", { staticClass: "yes" }, [_vm._v("Yes")]),
          _c("span", { staticClass: "no" }, [_vm._v("No")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" You have selected "),
      _c("span", { staticClass: "theme-text" }, [_vm._v(" 0 products ")]),
      _vm._v(" to compare ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("p", [
        _vm._v("Click the link below to find other Retailers in your area: "),
        _c(
          "a",
          {
            staticClass: "font-weight-bold text-primary",
            attrs: { href: "https://www.bosch-home.co.uk/dealer-locator" }
          },
          [_vm._v("https://www.bosch-home.co.uk/dealer-locator")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-" }, [
      _c("p", [
        _vm._v("If you have any questions please call "),
        _c(
          "a",
          {
            staticClass: "font-weight-bold text-primary",
            attrs: { href: "tel:03448928979" }
          },
          [_vm._v("0344 892 8979")]
        ),
        _vm._v(
          " and a member of our customer service team will assist you further with your query. "
        )
      ]),
      _c("p", [_vm._v("Please note the following: ")]),
      _c("ul", { staticClass: "pl-3" }, [
        _c("li", [
          _vm._v(
            "Please be aware that this is only a selection of retailers that stocks the selected appliance and that other retailers are available for your consideration. All pricing and stock availability is correct at the time the email was sent."
          )
        ]),
        _c("li", [
          _vm._v(
            "That you have checked the full specification of the appliance befor making the purchase, including the dimension, colour, installation type, spacing, plug type and voltage."
          )
        ]),
        _c("li", [
          _vm._v(
            "We do not offer any connection services for the appliance so please speak to your retailer regarding installation options."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }